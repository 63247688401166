<template>
  <div class="login" v-cloak>
       <div class="tops">
         <img src="../../assets/linshihuodonga.png" alt="">
       </div>
       <div class="bottom">
           <div class="input Verification Code">
             <p>您的手机号码<span style="color:#ff3040">*</span></p>
            <van-field class="accountNumber" v-model="tel" type="tel"  placeholder="请输入手机号码"/>
            <div>
            <p>验证码<span style="color:#ff3040">*</span></p>
             <van-field class="password"  v-model="password"  type="tel"  placeholder="请输入验证码"/>
             <div class="right" @click='sendout'>  
               <span v-show="show" @click="sendout">获取验证码</span>
               <span v-show="!show" class="count">{{count}} 秒</span>
             </div>
            </div>
          </div>

          <div class="logins" @click='details'>
            点击领取
          </div>
          <div class="agreement">
            <span>提交即视为您已阅读并同意</span><span style="color:#4081FF">《个人信息保护声明》</span>
          </div>
       </div>
        <div class="tops">
         <!-- <img src="../../assets/linshihuodongb.png" alt=""> -->
         <!-- <img src="../../assets/linshihuodongc.png" alt=""> -->
       </div>
        <div class="tops">
         <img src="../../assets/linshihuodongc.png" alt="">
       </div>
  </div>
</template>
<script>
import Cookies from 'js-cookie';
import { Toast } from 'vant';
import request from "../../plugin/request";
export default {
    data() {
    return {
      VerificationCode:false,
      password:'',
      tel:'',
      text:'获取验证码',
      img:false,
      show: true,
      count: '',
      timer: null,
      url:'',
      id:'',
      group_id:'',
      course_id:'',
      share_id:''
    };
  },
    mounted(){
    let currentQuery = this.$route.query;
    this.url=currentQuery.url
    this.id=currentQuery.id;
    this.group_id=currentQuery.group_id
    this.course_id=currentQuery.course_id
    this.share_id=currentQuery.share_id
    this.$nextTick(() => {
    });
  },
  methods:{
  getCode(){
 const TIME_COUNT = 60;
 if (!this.timer) {
 this.count = TIME_COUNT;
 this.show = false;
 this.timer = setInterval(() => {
 if (this.count > 0 && this.count <= TIME_COUNT) {
  this.count--;
  } else {
  this.show = true;
  clearInterval(this.timer);
  this.timer = null;
  }
 }, 1000)
 }
} ,
setToken(token){
       return Cookies.set('Token',  token)
          },
    imgs() {
       this.img=!this.img
    },
    switchs() {
      this.VerificationCode=!this.VerificationCode
      this.password=''
    },
    async sendout() {
      if(!(/^1[3456789]\d{9}$/.test(this.tel))){ 
        Toast('请输入正确手机号码');  
        return
     } else {
      this.getCode()
      const data = await request.post("/login/send", {
         event:'login',
         mobile:this.tel,
       });    
      console.log(data)
     }
     
    },
  // 登录
  async details() {
     if(!(/^1[3456789]\d{9}$/.test(this.tel))){ 
         Toast('请输入正确手机号码');  
     }  else {
       if(this.password==''){
         Toast("请输入密码或验证码！");
       }else {
            if (this.VerificationCode)  {
      var parameter = {
        method:'loginPassword',
        device:'h5',
        mobile:this.tel,
        password:this.password 
       }
      } else {
       var parameter = {
        method:'loginVerificationCode',
        device:'h5',
        mobile:this.tel,
        verificationCode:this.password
       }
      }
        const data = await request.post("/login",parameter);    
        console.log(data)
        if(data.code!=200) {
           Toast(data.message)
        } else {
           console.log(data.data.token)
           Toast('领取成功！正在跳转到下载页面...'); 
           this.$router.push({ path:'/download'}) 
        }   
      } 
     }
    },
  }
};
</script>
<style lang="less" >
.login {
  .tops {
    width: 100%;
    img {
      width: 100%;
      display:block;
    }
  }

  .bottom {
    background-color: #fff;
    width:100%;
    background-size: contain;
    padding-bottom:.64rem;
    .title  {
      font-size: .48rem;
      color: #2C2E30;
      font-weight: 400;
      width: 100%;
      text-align: center;
      margin-top:.8rem
    }
    .input  {
      
     .van-cell,.van-field {
       vertical-align:middle;
       
         width: 7.88rem;
         height: 1.12rem;
         background-size: 100% 100%;
         margin-top:.39rem;
         margin-left: .8rem;
         padding:0;
         line-height: 1.12rem;
      }
      .accountNumber .van-cell__title {
         width: .48rem;
         height: .57rem;
         span {
           display: none;
          }
       }
       .password  .van-cell__title {
         width: .44rem;
         height: .57rem;
         background: url(../../assets/dlmm.png);
         background-size: 100% 100%; 
         span {
           display: none;
          }
       }
    }
    .Verification {
      overflow: hidden;
      p {
        margin:0;
        margin-top:1.07rem;
        padding:0;
        font-size: .48rem;
        font-weight: bold;
        margin-left: .81rem;
       }
     .van-cell,.van-field {
         width: 7.88rem;
         height: 1.12rem;
         margin-top:.39rem;
         margin-left: .73rem;
      }
      .accountNumber .van-cell__title {

         span {
           display: none;
          }
       }
       .password  {
         width: 4.8rem;
display: inline-block;     
  }
       .right {
         vertical-align:-webkit-baseline-middle;
display: inline-block;     
         width: 4.03rem;
         height: 1.12rem;
         text-align: center;
         line-height: 1.12rem;
         font-size: .37rem;
         color: #2775FF;
       }
       .password  .van-cell__title {
         width: .44rem;
         height: .57rem;
         background: url(../../assets/dlmm.png);
         background-size: 100% 100%; 
         span {
           display: none;
          }
       }
    }
    .btn {
      width: 100%;
      height: .8rem;
      line-height: .8rem;
      margin-top:.11rem;
      .left {
        width: 2.01rem;
        height: .8rem;
        background-size: 100% 100%;
        text-align: center;
        line-height:  .8rem;
        font-size: .29rem;
        color: #B4BCCE;
        margin-left: .71rem;
        float: left;
        
      }
    .right {
        float: right;
        margin-right: 1.05rem;
        font-size: .29rem;
        color: #B4BCCE;
      }
    }
    .logins {
     width: 8.67rem;
height: 1.33rem;
background: #4081FF;
border-radius: 50px;
text-align: center;
line-height: 1.33rem ;
font-size: .43rem;
font-family: PingFang SC;
font-weight: 500;
color: #FFFFFF;
transform: translateX(-50%);
margin-left: 50%;
margin-top: .8rem;
    }
    .agreement {
      width: 100%;
      margin-top:.35rem;
      text-align: center;
      font-size: .29rem;

      span {
        font-size:.24rem;
font-family: PingFang SC;
font-weight: 500;
color: #CACACA;
      }
    }
  }
}
</style>